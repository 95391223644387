export default class Sort {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      buttons: 'data-sort',
      type: 'data-sort-type'
    }

    this.buttons = Array.from(document.querySelectorAll(`[${this.selectors.buttons}]`));

    if (!this.buttons.length) return false;


    return true;
  }

  setupEvents() {
    this.buttons.forEach((btn) => {
      btn.addEventListener('click', () => this.sort(btn));
    });
  }

  sort(btn) {
    const target = btn.getAttribute(this.selectors.buttons);
    const type = btn.getAttribute(this.selectors.type);
    const table = btn.closest('table');
    let mode = table.getAttribute('data-sort-mode');

    if (!mode) {
      table.setAttribute('data-sort-mode', 'asc');
    }

    this.buttons.forEach((btn) => {
      btn.classList.remove('dsc');
      btn.classList.remove('asc');
    });

    if (mode == 'asc') {
      table.setAttribute('data-sort-mode', 'desc');
      mode = 'desc';
      btn.classList.add('dsc');
    } else {
      table.setAttribute('data-sort-mode', 'asc');
      mode = 'asc';
      btn.classList.add('asc');
    }


    if (!type || !target || !table) {
      console.error(`Missing type, target or table`);
      return false;
    }

    const tableInner = table.querySelector('tbody');

    if (!tableInner) {
      console.error(`Missing TBODY tag`);
      return false;
    }

    const rows = tableInner.querySelectorAll('tr');

    if (!rows.length) {
      console.error(`No rows found in table`);
      return false;
    }

    this.switchLoop(tableInner, type, target, mode);
  }

  switchLoop(tableInner, type, target, mode) {
    let isSwitching = true;
    let shouldSwitch = false;
    let i = 0;
    let rows;

    while (isSwitching) {
      isSwitching = false;
      rows = Array.from(tableInner.querySelectorAll('tr'));
      Loop: for (i = 0; i < rows.length - 1; i++) {
        shouldSwitch = false;
        const currentRow = rows[i];
        const nextRow = rows[i + 1];
        const column = currentRow.querySelector(`td:nth-child(${parseInt(target) + 1})`);
        const nextRowColumn = nextRow.querySelector(`td:nth-child(${parseInt(target) + 1})`);

        if (type === 'symbol') {
          const valueA = column.innerHTML.toLowerCase();
          const valueB = nextRowColumn.innerHTML.toLowerCase();
          const splitA = valueA.split('.');
          const splitB = valueB.split('.');
          if (mode === 'desc') {
            if (splitA[0] > splitB[0]) {
              shouldSwitch = true;
              break Loop;
            } else if (splitA[0] == splitB[0] && parseInt(splitA[1]) > parseInt(splitB[1])) {
              shouldSwitch = true;
              break Loop;
            }
          } else {
            if (splitA[0] < splitB[0]) {
              shouldSwitch = true;
              break Loop;
            } else if (splitA[0] == splitB[0] && parseInt(splitA[1]) < parseInt(splitB[1])) {
              shouldSwitch = true;
              break Loop;
            }
          }

        } else if (type === 'number') {
          const valueA = parseFloat(column.innerHTML.replace("m<sup>2</sup>", ""));
          const valueB = parseFloat(nextRowColumn.innerHTML.replace("m<sup>2</sup>", ""));
          if (mode === 'desc') {
            if (valueA > valueB) {
              shouldSwitch = true;
              break Loop;
            }
          } else {
            if (valueA < valueB) {
              shouldSwitch = true;
              break Loop;
            }
          }
        } else if (type === 'text') {
          const valueA = column.innerHTML.toLowerCase();
          const valueB = nextRowColumn.innerHTML.toLowerCase();
          if (mode === 'desc') {
            if (valueA > valueB) {
              shouldSwitch = true;
              break Loop;
            }
          } else {
            if (valueA < valueB) {
              shouldSwitch = true;
              break Loop;
            }
          }
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        isSwitching = true;
      }
    }
  }
}

import Filters from './Filters';
import Sort from './Sort';

$(document).ready(function () {
  if (document.querySelector('.page-flats')) {
    const filters = this.filters = Array.from(document.querySelectorAll('[data-filters-wrapper]'));
    filters.forEach((filter) => {
      new Filters(filter);
    });
    new Sort();
  }
  runStickyHeader();
  runHomeSlider();
  runCounter();
  runSimpleLightbox();
  runMultipleSelect();
  runRangeSliders();
  runPopups();
  runMobileMenu();
  runScrollAnimation();
  runAutoFillTextarea();
  runGraphicSearch();
});

function runStickyHeader() {
  let menu = document.querySelector('.header');
	let origOffsetY = menu.offsetTop;
	function scroll() {
		if ($(window).scrollTop() > origOffsetY) {
			$('.header').addClass('scrolling');
		} else {
			$('.header').removeClass('scrolling');
		}
	}
	scroll();
	document.onscroll = scroll;
}

function runHomeSlider() {
  if (document.querySelector('.slider')) {
    var mySwiper = new Swiper ('.slider', {
      loop: true,
      speed: 500,
      effect: 'fade',
      autoplay: {
        delay: 5000
      },
      navigation: {
        prevEl: '.slider__nav .slider__prev',
        nextEl: '.slider__nav .slider__next'
      }
    });
  }
}

function runCounter() {
  $('.counter').easyCounter({
		'duration': 1500,
		'delay': 250,
		'attrmin': 'data-ec-min',
		'attrmax': 'data-ec-max',
		'attrrunonce': 'data-ec-run-once'
	});
}

function runSimpleLightbox() {
  if (document.querySelector('a.lightbox')) {
    $('a.lightbox').simpleLightbox({
      captions: false,
      showCounter: false,
      disableScroll: false
    });
  }
}

function runMultipleSelect() {
  if (document.querySelector('.page-flats')) {
    $('select.multiple-select').multipleSelect({
      placeholder: 'Wszystkie',
      selectAll: false,
      onClick: function(view) {
        window.dispatchEvent(
          new CustomEvent('filters-update', {
            detail: { type: 'select', view }
          })
        );
      }
    });
  }
}

function runRangeSliders() {
  if (document.querySelector('.page-flats')) {
    $(function() {
    	$('#slider-range-floors').slider({
    		range: true,
    		min: 0,
    		max: 3,
    		step: 1,
    		values: [0, 3],
    		slide: refreshRangeVal
    	});
    	setupVals('#slider-amount-floors', $('#slider-range-floors').slider('values',0), $('#slider-range-floors').slider('values',1));
    });
    $(function() {
    	$('#slider-range-rooms').slider({
    		range: true,
    		min: 2,
    		max: 3,
    		step: 1,
    		values: [2, 3],
    		slide: refreshRangeVal
    	});
    	setupVals('#slider-amount-rooms', $('#slider-range-rooms').slider('values',0), $('#slider-range-rooms').slider('values',1));
    });
    function refreshRangeVal(e, ui) {
    	var min = ui.values[0];
    	var max = ui.values[1];
    	var target = $(e.target).closest('.range').find('.range__amount');
    	var id = '#' + $(target).attr('id');
    	setupVals(id, min, max);
      updateFilters({
        type: 'range',
        min: ui.values[0],
        max: ui.values[1],
        target
      });
    }
    function setupVals(id, min, max) {
    	var target = $(id);
    	var text = '<div class="min">' + min + '</div> <div class="max">' + max + '</div>';
    	$(target).text('');
    	$(target).append(text);
    }
    $(function() {
    	$('#slider-range-surface').slider({
    		range: true,
    		min: 30,
    		max: 92,
    		step: 2,
    		values: [30, 92],
    		slide: refreshRangeValSurface
    	});
    	setupValsSurface('#slider-amount-surface', $('#slider-range-surface').slider('values',0), $('#slider-range-surface').slider('values',1));
    });
    function refreshRangeValSurface(e, ui) {
    	var min = ui.values[0] + 'm<sup>2</sup>';
    	var max = ui.values[1] + 'm<sup>2</sup>';
    	var target = $(e.target).closest('.range').find('.range__amount');
    	var id = '#' + $(target).attr('id');
    	setupVals(id, min, max);
      updateFilters({
        type: 'range',
        min: ui.values[0],
        max: ui.values[1],
        target
      });
    }
    function setupValsSurface(id, min, max) {
    	var target = $(id);
    	var text = '<div class="min">' + min + 'm<sup>2</sup></div> <div class="max">' + max + 'm<sup>2</sup></div>';
    	$(target).text('');
    	$(target).append(text);
    }
  }
}

function runPopups() {
  if (document.querySelector('#popup')) {
    $('#popup').popup({
      color: '#364922',
      opacity: 0.75,
      transition: 'all 0.4s'
    });
  }
}

function runMobileMenu() {
  $('.header__trigger').on('click',function() {
    $(this).stop(true,true).toggleClass('visible');
    $('.header__menu').stop(true,true).toggleClass('visible');
	});
}

function runScrollAnimation() {
  AOS.init({
    duration: 800,
    delay: 100,
    offset: 200,
    easing: 'ease-out',
    once: true,
    disable: 'mobile'
  });
}

function updateFilters(detail) {
  window.dispatchEvent(new CustomEvent('filters-update', { detail }));
}

function runAutoFillTextarea() {
  if (document.querySelector('.page-flat')) {
    const sendButton = document.querySelector('[data-flat-id]');
    const formMessage = document.getElementById('form-message');
    if (sendButton && formMessage) {
      const id = sendButton.getAttribute('data-flat-id');
      formMessage.innerHTML = `Witam. Interesuje mnie mieszkania ${id}. Proszę o kontakt.`;
    }
  }
}

function runGraphicSearch() {
  if ($('#js-sorter').length > 0) {
		$('#js-sorter').tablesorter();
	}

	$('#js-search3d-step-1 .js-floor-link').click(function (e) {
		e.preventDefault();
		var self = $(this);
		var id = self.attr('id');
		var link = $('#floor-link-' + id);
		window.location.href = link.attr('href');
	});

	$('#js-search3d-step-2 .js-apartment-link').click(function (e) {
		e.preventDefault();
		var self = $(this);
		var id = self.attr('id');
		var link = $('#js-apartment-link-' + id);
		if (!self.hasClass('sold')) {
			var apartment = window.open(
				link.attr('href'), "_blank");
			apartment.focus();
		}
	});

	$('#js-search3d-step-2 .js-apartment-link').each(function (e) {
		var self = $(this);
		var id = self.attr('id');
		var link = $('#js-apartment-link-' + id);
		if (link.hasClass('flat-status-sold')) {
			self.addClass('sold');
		} else if (link.hasClass('flat-status-reservation')) {
			self.addClass('reserved');
		}
	});

	$('#js-search3d-step-2 .js-apartment-link').on('mouseover', function () {
		var self = $(this);
		var id = self.attr('id');
		var link = $('#js-apartment-link-' + id);
		if (link.length > 0) {
			$('#js-api-cloud-contetns').show();
			$('#js-api-cloud-symbol').html(link.data('symbol'));
			$('#js-api-cloud-area').html(link.data('area'));
			$('#js-api-cloud-rooms').html(link.data('rooms'));
			$('#js-api-cloud-status').removeClass('status-available').removeClass('status-reservation').removeClass('status-sold').addClass(link.data('status')).html(link.data('statuslabel'));
			if (link.data('price') !== undefined) {
				$('#js-api-cloud-price').html(link.data('price'));
			}
		} else {
			$('#js-api-cloud-symbol').html('--');
			$('#js-api-cloud-contetns').hide();
			$('#js-api-cloud-status').removeClass('status-available').removeClass('status-reservation').removeClass('status-sold').addClass('status-sold').html('Sprzedane');
		}
		self.mousemove(function (e) {
			var yPos = parseInt(e.pageY) - parseInt(180);
			var xPos = parseInt(e.pageX) + parseInt(10);
			$('#js-api-cloud').offset({'top': yPos, 'left': xPos}).addClass('show');
		});
		$('#js-api-cloud').addClass('show');
	}).on('mouseleave', function () {
		$('#js-api-cloud').removeClass('show');
	});

	$('#js-search3d-step-1 .js-floor-link').on('mouseover', function () {
		var self = $(this);
		var id = self.attr('id');
		var link = $('#floor-link-' + id);
		$('#js-api-b-cloud-building').html(link.data('building'));
		$('#js-api-b-cloud-floor').html(link.data('floor'));
		$('#js-api-b-cloud-available').html('');
		self.mousemove(function (e) {
			var yPos = parseInt(e.pageY) - parseInt(120);
			var xPos = parseInt(e.pageX) + parseInt(10);
			$('#js-api-cloud-2').offset({'top': yPos, 'left': xPos}).addClass('show');
		});
		$('#js-api-cloud-2').addClass('show');
	}).on('mouseleave', function () {
		$('#js-api-cloud-2').removeClass('show');
	});
}

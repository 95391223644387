export default class Filters {
  constructor(filter) {
    if (!this.vars(filter)) return false;
    this.setupEvents();
  }

  vars(filter) {
    if (!filter) {
      console.error('No target DOM Element specified');
      return false;
    }

    this.selectors = {
      filtersWrapper: 'data-filters-wrapper',
      filtersFor: 'data-filter-for',
      inputType: 'data-type',
    };

    this.filterEl = filter;
    const targetTable = this.filterEl.getAttribute(`${this.selectors.filtersWrapper}`);
    if (!targetTable) {
      console.error('data-filters-wrapper has no ID assigned to it');
      return false;
    }

    this.table = document.getElementById(targetTable);

    if (!this.table) {
      console.error(`Assigned ID could not be found: ${this.targetTable}`);
      return false;
    }

    this.rows = this.table.querySelectorAll('tr');

    if (!this.rows.length) {
      console.error(`Specified table has no rows: ${this.targetTable}`);
      return false;
    }

    return true;
  }

  setupEvents() {
    window.addEventListener('filters-update', (e) => this.filter(e));
  }

  filter(e) {
    if (e.detail.type === 'range') {
      const { min, max } = e.detail;
      const target = e.detail.target[0];
      const column = parseInt(target.getAttribute(this.selectors.filtersFor));
      this.filterEl.setAttribute(`data-filters-${column}-min`, min);
      this.filterEl.setAttribute(`data-filters-${column}-max`, max);
    } else if (e.detail.type === 'select') {
      const selectEl = this.filterEl.querySelector('.multiple-select');
      const column = parseInt(selectEl.getAttribute(this.selectors.filtersFor));
      const select = Array.from(this.filterEl.querySelectorAll(['.ms-drop input[type="checkbox"]:checked']));
      const selectedIds = select.map((item) => item.value);
      this.filterEl.setAttribute(`data-filters-${column}-selected`, JSON.stringify(selectedIds));
    }

    this.toggleVisibility();
  }

  toggleVisibility() {
    let hiddenRows = 0;
    this.rows.forEach((row) => {
      const columns = Array.from(row.children);
      let shouldHide = false;
      columns.forEach((column, i) => {
        const min = this.filterEl.getAttribute(`data-filters-${i}-min`);
        const max = this.filterEl.getAttribute(`data-filters-${i}-max`);
        const selected = this.filterEl.getAttribute(`data-filters-${i}-selected`);
        if (min && max) {
          let value = column.innerHTML;
          value = parseFloat(value.replace("m<sup>2</sup>", ""));
          if (value > max || value < min) shouldHide = true;
        } else if (selected) {
          let value = column.innerText;
          const selectedIds = JSON.parse(selected);
          if (selectedIds.length && selectedIds.indexOf(value) < 0) shouldHide = true;
        }
      });

      if (shouldHide) {
        row.style.display = 'none';
        hiddenRows++;
      } else {
        row.style.display = '';
      }
    });

    const msgBox = document.querySelector('.flats__empty');
    if (msgBox) {
      if (hiddenRows === this.rows.length) {
        msgBox.style.display = 'block';
      } else {
        msgBox.style.display = '';
      }
    }
  }
}
